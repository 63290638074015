<template>
    <div>
        <el-main>
            <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
        </el-main>
    </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import website from '@/config/website';
import { mapGetters } from "vuex";
import { add, update, getRoleTreeById, getDetail} from "@/api/system/role";
export default {
    components: { FormLayout, HeadLayout },
  props: {
    dataObj:{
      type: Object,
      default: () => {
        return {};
      },
    },
    tenantStatus:{
      type: String,
      default: "true",
    }
  },
    data() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 100) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t("cip.plat.authority.role.field.roleName") + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
        return {
            headBtnOptions: [{
                label: this.$t('cip.cmn.btn.saveBtn'),
                emit: "head-save",
                type: "button",
                icon: ""
            },
            {
                label: this.$t('cip.cmn.btn.saveBackBtn'),
                emit: "head-save-back",
                type: "button",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.celBtn'),
                emit: "head-cancel",
                type: "button",
                icon: ""
            }],
            dataObj: {},
            parentId: 0,
            column: [
                {
                    label: this.$t("cip.plat.authority.role.field.roleName"),
                    prop: "roleName",
                    search: true,
                    isGlobal:true, //是否支持国际化
                    rules: [{ required: true, validator: validatePass, trigger: 'blur' }]
                },
                {
                    label: this.$t("cip.plat.authority.role.field.tenantId"),
                    prop: "tenantId",
                    type: "select",
                    dicUrl: "/api/sinoma-system/tenant/select",
                    addDisplay: false,
                    editDisplay: false,
                    viewDisplay: website.tenantMode,

                    props: {
                        label: "tenantName",
                        value: "tenantId"
                    },
                    hide: !website.tenantMode,
                    search: website.tenantMode,
                    rules: [{
                        required: true,
                        message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.tenantId"),
                        trigger: "click"
                    }]
                },
                {
                    label: this.$t("cip.plat.authority.role.field.roleAlias"),
                    prop: "roleAlias",
                    search: true,
                    maxlength: 60,
                    showWordLimit: true,
                    rules: [{
                        required: true,
                        message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.roleAlias"),
                        trigger: 'blur'
                    },{
                      pattern: /^[^\s]+(\s+[^\s]+)*$/,
                      message: this.$t("cip.cps.msg.removeSpaces"),
                    },
                      {
                        pattern: '^[a-zA-Z0-9_]{0,}$',
                        message: this.$t("cip.cps.device.msg.chinese"),
                      }
                    ]
                },
                {
                    label: this.$t("cip.plat.authority.role.field.roleType"),
                    type: "select",
                    dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
                    props: {
                      label: "dictValue",
                      value: "dictKey"
                    },
                    span: 8,
                    value: 1,
                    disabled:true,
                    display:false,
                    dataType: "number",
                    prop: "roleType",
                    rules: [{
                    required: true,
                    message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.roleType"),
                    trigger: "blur"
                  }]
                },
              {
                label: this.$t("cip.plat.authority.role.field.sort"),
                prop: "sort",
                type: "number",
                value: 0,
                minRows: 0,
                maxRows: 1000000000,
              },
                {
                  label: "角色分类",
                  prop: "roleGroup",
                  span: 16,
                  multiple: true,
                  placeholder: "请选择角色分类",
                  type: "select",
                  dicUrl:
                      "/api/sinoma-system/dict/dictionary?code=role_group_type",
                  props: {
                    label: "dictValue",
                    value: "dictKey",
                  },
                  dataType: "string",
                },

            ]
        }
    },
    computed: {
        ...mapGetters(['language'])
    },
    created() {

      if(this.tenantStatus != "true"){
        this.findObject(this.column, "tenantId").display = false
        this.dataObj.tenantId = "000000";
      }
      if (this.dataObj.id) {
          getDetail(this.dataObj.id).then(res => {
            const {data} = res;
            this.dataObj = data.data;
          })
      }

    },
    mounted() { },
    methods: {
        headSave(type) {
            this.$refs.formLayout.$refs.form.validate(valid => {
                if (valid) {
                  this.$loading()
                    let obj = this.$refs.formLayout.dataForm
                    let requestType = obj.hasOwnProperty('id') ? update : add
                    requestType(this.dataObj).then((res) => {

                        let { data } = res.data
                        this.$message({
                            type: "success",
                            message: this.$t("cip.cmn.msg.success.operateSuccess"),
                        });
                        this.dataObj = data;
                      this.$emit("cancel")
                    }, error => {
                        window.console.log(error);

                        this.$refs.formLayout.$refs.form.allDisabled = false
                    }).finally(()=>{
                      this.$loading().close()
                      this.$emit("cancel")
                    });
                } else {
                    this.$refs.formLayout.$refs.form.allDisabled = false
                }
            })
        },
        headCancel() {
          this.$router.$avueRouter.closeTag();
          this.$router.back();
        },
    }
}
</script>

<style>
</style>
